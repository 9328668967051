<section id="matches" class="upcoming-matches-area pt-100 pb-70">
    <div class="container">
        <!--<div class="section-title">
            <h2>Competições</h2>->
            <!--<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan
                lacus vel facilisis.</p>->
        </div>-->

        <div>
            <div class="row justify-content-center"> <!-- justify-content-between -->
                <div class="col-sm-4">
                    <h3>Classificação Geral</h3>
                    <table class="table general general-results">
                        <thead class="table-dark">
                            <tr>
                                <th scope="col">Pos.</th>
                                <th scope="col">Equipa</th>
                                <th scope="col">Pts</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let result of generalClassification; let i = index;">
                                <td>{{ i + 1 }}</td><!--[class.bold]="i <= 2"-->
                                <td>{{ result.team }}</td>
                                <td>{{ result.classificacao_geral.total_points }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-sm-4">
                    <h3>Taça André Monteiro</h3>
                    <table class="table general cup">
                        <thead class="table-dark">
                            <tr>
                                <!--<th scope="col">Pos.</th>-->
                                <th scope="col"></th>
                                <th scope="col">Equipa</th>
                                <th scope="col"></th>

                                <!--<th scope="col">Pts</th>-->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let result of ricardoSilvaCup; let i = index;" ><!--[class.dark]="i > 2"-->
                                <!--<td [class.bold]="i <= 2">{{ i + 1}}</td>-->
                                <td></td>
                                <td>{{ result.team }}</td><!--[class.bold]="i <= 2"-->
                                <td></td>
                                <!--<td [class.bold]="i <= 2">{{ result.ricardo_silva.total_points }}</td>-->
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="3"><a routerLink="competicoes/taca-andre-monteiro">ver mais</a></td>
                            </tr>
                        </tfoot>
                    </table>
                    <!-- 
                    <div class="upcoming-matches-slides owl-carousel owl-theme">
                        <div class="single-upcoming-matches-item">
                            
                        </div>
                    </div>
                -->
                </div>
                <div class="col-sm-4 champions-league" *ngIf="championsCurrentJourney">
                    <h3>Liga dos Campeões</h3>

                    <div id="championsCarouselControls" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item" [class.active]="i === 0"
                                *ngFor="let group of groupsAvailable; let i = index;">
                                <div class="row justify-content-center">
                                    <div class="col-sm-8">
                                        <h5>Grupo {{group}}</h5>
                                        <table class="table general champions-league">
                                            <thead class="table-dark">
                                                <tr>
                                                    <th scope="col">Pos.</th>
                                                    <th scope="col">Equipa</th>
                                                    <th scope="col">Pts</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let result of championsCurrentJourney[group]; let j = index;">
                                                    <td [class.bold]="j < 4">{{ j + 1 }}</td><!--[class.bold]="j < 4"-->
                                                    <td [class.bold]="j < 4">{{ result.team }}</td>
                                                    <td [class.bold]="j < 4">{{ result.total_points }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#championsCarouselControls"
                            data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#championsCarouselControls"
                            data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                <!-- COMENTAR AQUI 
                <div class="col-sm-4 champions-league">
                    <h3>Liga dos Campeões</h3>
                    <img src="https://firebasestorage.googleapis.com/v0/b/fantasy-premier.appspot.com/o/tournaments%2FPlayoff_LigadosCampeoes_Mobile.png?alt=media&token=94a18a1f-e1cf-4d7b-95e2-a80af99aaabd" alt="">
                    <!-- 
                    <div class="upcoming-matches-slides owl-carousel owl-theme">
                        <div class="single-upcoming-matches-item">
                            
                        </div>
                    </div>
                    ->
                </div>
            FIM AQUI -->
            </div>
            <div class="row justify-content-center mt-5">
                <div class="col-sm-4">
                    <h3>Conference League</h3>
                    <!-- <img src="https://firebasestorage.googleapis.com/v0/b/fantasy-premier.appspot.com/o/tournaments%2FPlayoff_ConferenceLeague_Mobile.png?alt=media&token=94a18a1f-e1cf-4d7b-95e2-a80af99aaabd" alt=""> -->

                    <table class="table general cup">
                        <thead class="table-dark">
                            <tr>
                                <!--<th scope="col">Pos.</th>-->
                                <th scope="col"></th>
                                <th scope="col">Equipa</th>
                                <th scope="col"></th>

                                <!--<th scope="col">Pts</th>-->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let result of conferenceLeague['A']; let i = index;" ><!--[class.dark]="i > 2"-->
                                <!--<td [class.bold]="i <= 2">{{ i + 1}}</td>-->
                                <td [class.bold]="i < 8"></td>
                                <td [class.bold]="i < 8">{{ result.team }}</td><!--[class.bold]="i <= 2"-->
                                <td [class.bold]="i < 8"></td>
                                <!--<td [class.bold]="i <= 2">{{ result.ricardo_silva.total_points }}</td>-->
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="3"><a routerLink="competicoes/conference-league">ver mais</a></td>
                            </tr>
                        </tfoot>
                    </table>
                    <!-- 
                    <div class="upcoming-matches-slides owl-carousel owl-theme">
                        <div class="single-upcoming-matches-item">
                            
                        </div>
                    </div>
                -->
                </div>

                <div class="col-sm-4">
                    <h3>Liga Europa</h3>
                    <!-- <img src="https://firebasestorage.googleapis.com/v0/b/fantasy-premier.appspot.com/o/tournaments%2FPlayoff_LigaEuropa_Mobile.png?alt=media&token=94a18a1f-e1cf-4d7b-95e2-a80af99aaabd" alt=""> -->

                    <div id="ligaEuropaCarouselControls" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item" [class.active]="i === 0"
                                *ngFor="let group of groupsAvailableLigaEuropa; let i = index;">
                                <div class="row justify-content-center">
                                    <div class="col-sm-8">
                                        <h5>Grupo {{group}}</h5>
                                        <table class="table general champions-league">
                                            <thead class="table-dark">
                                                <tr>
                                                    <th scope="col">Pos.</th>
                                                    <th scope="col">Equipa</th>
                                                    <th scope="col">Pts</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let result of ligaEuropa[group]; let j = index;">
                                                    <td [class.bold]="j === 0" [class.semi-bold]="j === 1">{{ j + 1 }}</td><!--[class.bold]="j < 4"-->
                                                    <td [class.bold]="j === 0" [class.semi-bold]="j === 1">{{ result.team }}</td>
                                                    <td [class.bold]="j === 0" [class.semi-bold]="j === 1">{{ result.total_points }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#ligaEuropaCarouselControls"
                            data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#ligaEuropaCarouselControls"
                            data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>

                   
                    <!-- 
                    <div class="upcoming-matches-slides owl-carousel owl-theme">
                        <div class="single-upcoming-matches-item">
                            
                        </div>
                    </div>
                -->
                </div>
            </div>
        </div>


    </div>

    <!--<div class="upcoming-matches-shape1"><img src="assets/img/player1.png" alt="image"></div>-->
</section>