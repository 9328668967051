<section class="tournament-details-area pt-100">
    <div [ngSwitch]="tournamentID">
        <div class="container-fluid bg-light" *ngSwitchCase="'andre-monteiro'">

            <div class="row cup">
                <div class="col-md-12 p-0">
                    <div class="tournament-details-header bg-purple">
                        <img src="../../../../assets/img/tournaments/tacaandremonteiro.png" class="img-fluid" alt="">
                    </div>
                </div>
                <div class="col-md-12 results">
                    <div class="row justify-content-end">
                        <div class="col-md-12">
                            <h2>Classificação</h2>
                        </div>
                        <!--<div class="col-md-6 text-end">
                            <ul class="list-unstyled">
                                <li>Regulamento</li>
                            </ul>
                        </div>-->
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row justify-content-center">
                        <div class="col-sm-4">
                            <table class="table">
                                <thead class="table-dark">
                                    <tr>
                                        <th scope="col"></th><!--Pos.-->
                                        <th scope="col">Equipa</th>
                                        <th scope="col"></th><!--Pts-->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let player of allPlayers; let i = index;"
                                        [class.inactive]="player.status === 'inactive'"><!-- [class.top]="i <= 2"-->
                                        <!--<td>{{ i + 1 }}</td>-->
                                        <td></td>
                                        <td>{{ player.team }}</td>
                                        <td></td>
                                        <!--<td>{{ player.total_points }}</td>-->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-light container-fluid" *ngSwitchCase="'liga-campeoes'">
            <!-- COMENTAR AQUI
                 <div class="row">
                <div class="col-md-12 p-0">
                    <div class="tournament-details-header bg-light">
                        <img src="../../../../assets/img/tournaments/LigadosCampeoes.png" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 p-0">
                    <div class="results bg-light desktop">
                        <img src="https://firebasestorage.googleapis.com/v0/b/fantasy-premier.appspot.com/o/tournaments%2FPlayoff_LigadosCampeoes.png?alt=media&token=94a18a1f-e1cf-4d7b-95e2-a80af99aaabd" class="img-fluid" alt="">
                    </div>
                    <div class="results bg-light mobile">
                        <img src="https://firebasestorage.googleapis.com/v0/b/fantasy-premier.appspot.com/o/tournaments%2FPlayoff_LigadosCampeoes_Mobile.png?alt=media&token=94a18a1f-e1cf-4d7b-95e2-a80af99aaabd" class="img-fluid" alt="">
                    </div>
                </div>
            </div>  
        TERMINA AQUI -->
             <div class="container">
                <div class="row">
                    <div class="col-md-12 results">
                        <h2>Classificação</h2>
                        <!--<div class="col-md-6 text-end">
                            <ul class="list-unstyled">
                                <li>Regulamento</li>
                            </ul>
                        </div>-->
                    </div>
                    <div class="col-sm-12 groups-results">
                        <div class="row justify-content-around">

                            <div class="col-md-6" *ngFor="let group of groupsAvailable; let i = index;">
                                <h4>Grupo {{group}}</h4>
                                <table class="table">
                                    <thead class="table-dark">
                                        <tr>
                                            <th scope="col">Pos.</th>
                                            <th scope="col">Equipa</th>
                                            <th scope="col">Pts</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let player of finalScoresTable[group]; let j = index;"
                                            [class.top]="j < 3">
                                            <td>{{ j + 1 }}</td>
                                            <td>{{ player.team }}</td>
                                            <td>{{ player.total_points }}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 next-week" *ngIf="previousWeek">
                        <h2>Última Jornada</h2>
                        <h4>Jornada {{previousWeek}}</h4>
                        <div class="row justify-content-center">
                            <div class="col-sm-8" *ngFor="let group of groupsAvailablePrevious; let i = index;"
                                [class.dark]="i % 2 === 0">
                                <table class="table table-borderless">
                                    <thead class="table-light">
                                        <tr>
                                            <th scope="col" colspan="5" class="text-center">Grupo {{group}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let journey of previousGames[group]; let j = index;">
                                            <td class="score" [class.loser]="journey.home.points < journey.away.points">
                                                {{ journey?.home?.points }}</td>
                                            <td class="home" style="width: 42.5%;">{{ journey?.home?.team }}</td>
                                            <td class="versus"></td>
                                            <td class="away" style="width: 42.5%;">{{ journey?.away?.team }}</td>
                                            <td class="score" [class.loser]="journey.home.points > journey.away.points">
                                                {{ journey?.away?.points }}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                    <div class="col-sm-12 next-week" *ngIf="upcomingWeek">
                        <h2>Próxima Jornada</h2>
                        <h4>Jornada {{upcomingWeek}}</h4>
                        <div class="row justify-content-center">
                            <div class="col-sm-8" *ngFor="let group of groupsAvailable; let i = index;"
                                [class.dark]="i % 2 === 0">
                                <table class="table table-borderless">
                                    <thead class="table-light">
                                        <tr>
                                            <th scope="col" colspan="5" class="text-center">Grupo {{group}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let journey of upcomingGames[group]; let j = index;">
                                            <td [class.opacity-0]="journey.home.points === 0 && journey.away.points === 0"
                                                class="score" [class.loser]="journey.home.points < journey.away.points">
                                                {{ journey?.home?.points }}</td>
                                            <td class="home" style="width: 42.5%;">{{ journey?.home?.team }}</td>
                                            <td class="versus"></td>
                                            <td class="away" style="width: 42.5%;">{{ journey?.away?.team }}</td>
                                            <td [class.opacity-0]="journey.home.points === 0 && journey.away.points === 0"
                                                class="score" [class.loser]="journey.home.points > journey.away.points">
                                                {{ journey?.away?.points }}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

        <div class="bg-light container-fluid" *ngSwitchCase="'conference-league'">
            <!-- <div class="row">
                <div class="col-md-12 p-0">
                    <div class="tournament-details-header bg-green">
                        <img src="../../../../assets/img/tournaments/ConferenceLeague.png" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 p-0">
                    <div class="results bg-light desktop">
                        <img src="https://firebasestorage.googleapis.com/v0/b/fantasy-premier.appspot.com/o/tournaments%2FPlayoff_ConferenceLeague.png?alt=media&token=94a18a1f-e1cf-4d7b-95e2-a80af99aaabd" class="img-fluid" alt="">
                    </div>
                    <div class="results bg-light mobile">
                        <img src="https://firebasestorage.googleapis.com/v0/b/fantasy-premier.appspot.com/o/tournaments%2FPlayoff_ConferenceLeague_Mobile.png?alt=media&token=94a18a1f-e1cf-4d7b-95e2-a80af99aaabd" class="img-fluid" alt="">
                    </div>
                </div>
            </div> -->

            <div class="container">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="row">
                            <div class="col-md-12 results">
                                <h2>Classificação</h2>
                                <!--<div class="col-md-6 text-end">
                                    <ul class="list-unstyled">
                                        <li>Regulamento</li>
                                    </ul>
                                </div>-->
                            </div>
                            <div class="col-md-12 groups-results">
                                <div class="row justify-content-center">
                                    <div class="col-sm-12">
                                        <table class="table">
                                            <thead class="table-dark">
                                                <tr>
                                                    <th scope="col">Pos.</th><!---->
                                                    <th scope="col">Equipa</th>
                                                    <th scope="col">Pts</th><!---->
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let player of finalScoresTable['A']; let i = index;"
                                                    [class.inactive]="player.status === 'inactive'" [class.top]="i < 8">
                                                    <!-- [class.top]="i <= 2"-->
                                                    <td>{{ i + 1 }}</td>
                                                    <td>{{ player.team }}</td>
                                                    <td>{{ player.total_points }}</td>
                                                    <!--<td>{{ player.total_points }}</td>-->
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="row">
                            <div class="col-sm-12 next-week pt-4" *ngIf="previousWeek">
                                <h2>Última Jornada</h2>
                                <h4>Jornada {{previousWeek}}</h4>
                                <div class="row justify-content-center">
                                    <div class="col-12">
                                        <table class="table table-borderless">
                                            <thead class="table-light">
                                                <!-- <tr>
                                                    <th scope="col" colspan="5" class="text-center">{{ previousWeek % 2
                                                        === 0 ? '2ª Mão' : '1ª Mão'}}</th>
                                                </tr> -->
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let journey of previousGames; let j = index;">
                                                    <td class="score"
                                                        [class.loser]="journey.home.total_points < journey.away.total_points">
                                                        {{ journey?.home?.points }}</td>
                                                    <td class="home" style="width: 42.5%;">{{ journey?.home?.team }}
                                                    </td>
                                                    <td class="versus"></td>
                                                    <td class="away" style="width: 42.5%;">{{ journey?.away?.team }}
                                                    </td>
                                                    <td class="score"
                                                        [class.loser]="journey.home.total_points > journey.away.total_points">
                                                        {{ journey?.away?.points }}</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                            <div class="col-sm-12 next-week" *ngIf="upcomingWeek && upcomingGames && upcomingGames.length > 0">
                                <h2>Próxima Jornada</h2>
                                <h4>Jornada {{upcomingWeek}}</h4>
                                <div class="row justify-content-center">
                                    <div class="col-12">
                                        <table class="table table-borderless">
                                            <thead class="table-light">
                                                <!-- <tr>
                                                    <th scope="col" colspan="5" class="text-center">{{ upcomingWeek % 2
                                                        === 0 ? '2ª Mão' : '1ª Mão'}}</th>
                                                </tr> -->
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let journey of upcomingGames; let j = index;">
                                                    <td [class.opacity-0]="journey.home.total_points === 0 && journey.away.total_points === 0"
                                                        class="score"
                                                        [class.loser]="journey.home.total_points < journey.away.total_points">
                                                        {{ journey?.home?.total_points }}</td>
                                                    <td class="home" style="width: 42.5%;">{{ journey?.home?.team }}
                                                    </td>
                                                    <td class="versus"></td>
                                                    <td class="away" style="width: 42.5%;">{{ journey?.away?.team }}
                                                    </td>
                                                    <td [class.opacity-0]="journey.home.total_points === 0 && journey.away.total_points === 0"
                                                        class="score"
                                                        [class.loser]="journey.home.total_points > journey.away.total_points">
                                                        {{ journey?.away?.total_points }}</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>

        <div class="bg-light container-fluid" *ngSwitchCase="'liga-europa'">
            <!-- <div class="row">
                <div class="col-md-12 p-0">
                    <div class="tournament-details-header bg-dark-purple">
                        <img src="../../../../assets/img/tournaments/LigaEuropa.png" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 p-0">
                    <div class="results bg-light desktop">
                        <img src="https://firebasestorage.googleapis.com/v0/b/fantasy-premier.appspot.com/o/tournaments%2FPlayoff_LigaEuropa.png?alt=media&token=94a18a1f-e1cf-4d7b-95e2-a80af99aaabd" class="img-fluid" alt="">
                    </div>
                    <div class="results bg-light mobile">
                        <img src="https://firebasestorage.googleapis.com/v0/b/fantasy-premier.appspot.com/o/tournaments%2FPlayoff_LigaEuropa_Mobile.png?alt=media&token=94a18a1f-e1cf-4d7b-95e2-a80af99aaabd" class="img-fluid" alt="">
                    </div>
                </div>
            </div> -->

            <div class="container">
                <div class="row">
                    <div class="col-md-12 results">
                        <h2>Classificação</h2>
                        <!--<div class="col-md-6 text-end">
                            <ul class="list-unstyled">
                                <li>Regulamento</li>
                            </ul>
                        </div>-->
                    </div>
                    <div class="col-sm-12 groups-results">
                        <div class="row justify-content-around">
                            <div class="col-md-6" *ngFor="let group of groupsAvailable; let i = index;">
                                <h4>Grupo {{group}}</h4>
                                <table class="table">
                                    <thead class="table-dark">
                                        <tr>
                                            <th scope="col">Pos.</th>
                                            <th scope="col">Equipa</th>
                                            <th scope="col">Pts</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let player of finalScoresTable[group]; let j = index;"
                                            [class.top]="j < 1" [class.second-top]="j === 1">
                                            <td>{{ j + 1 }}</td>
                                            <td>{{ player.team }}</td>
                                            <td>{{ player.total_points }}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 next-week" *ngIf="previousWeek">
                        <h2>Última Jornada</h2>
                        <h4>Jornada {{previousWeek}}</h4>
                        <div class="row justify-content-center">
                            <div class="col-sm-8" *ngFor="let group of groupsAvailablePrevious; let i = index;"
                                [class.dark]="i % 2 === 0">
                                <table class="table table-borderless">
                                    <thead class="table-light">
                                        <tr>
                                            <th scope="col" colspan="5" class="text-center">Grupo {{group}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let journey of previousGames[group]; let j = index;">
                                            <td class="score" [class.loser]="journey.home.points < journey.away.points">
                                                {{ journey?.home?.points }}</td>
                                            <td class="home" style="width: 42.5%;">{{ journey?.home?.team }}</td>
                                            <td class="versus"></td>
                                            <td class="away" style="width: 42.5%;">{{ journey?.away?.team }}</td>
                                            <td class="score" [class.loser]="journey.home.points > journey.away.points">
                                                {{ journey?.away?.points }}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                    <div class="col-sm-12 next-week" *ngIf="upcomingWeek">
                        <h2>Próxima Jornada</h2>
                        <h4>Jornada {{upcomingWeek}}</h4>
                        <div class="row justify-content-center">
                            <div class="col-sm-8" *ngFor="let group of groupsAvailable; let i = index;"
                                [class.dark]="i % 2 === 0">
                                <table class="table table-borderless">
                                    <thead class="table-light">
                                        <tr>
                                            <th scope="col" colspan="5" class="text-center">Grupo {{group}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let journey of upcomingGames[group]; let j = index;">
                                            <td [class.opacity-0]="journey.home.points === 0 && journey.away.points === 0"
                                                class="score" [class.loser]="journey.home.points < journey.away.points">
                                                {{ journey?.home?.points }}</td>
                                            <td class="home" style="width: 42.5%;">{{ journey?.home?.team }}</td>
                                            <td class="versus"></td>
                                            <td class="away" style="width: 42.5%;">{{ journey?.away?.team }}</td>
                                            <td [class.opacity-0]="journey.home.points === 0 && journey.away.points === 0"
                                                class="score" [class.loser]="journey.home.points > journey.away.points">
                                                {{ journey?.away?.points }}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

        <div class="row justify-content-center" *ngSwitchDefault>
            <div class="col-sm-6 text-center">
                Sem informações sobre esta competição
            </div>
        </div>



    </div>
</section>